// @flow
import * as React from 'react';

import type { ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { MenuItem, Toggleable } from 'components/index';

import ReOpenPeersFeedbackModal from './ReOpenPeersFeedbackModal';

type Props = {|
  userReview: ThreeSixtyUserReview,
  onAfterAction?: () => any,
  disabled?: boolean,
|};

export default function ReOpenPeersFeedbackAction({
  userReview,
  onAfterAction,
  disabled,
}: Props) {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <React.Fragment>
          <MenuItem onClick={toggle} disabled={disabled}>
            {__('Re-open peers feedback')}
          </MenuItem>
          <ReOpenPeersFeedbackModal
            isActive={isToggled}
            toggleModal={toggle}
            userReview={userReview}
            onAfterAction={onAfterAction}
          />
        </React.Fragment>
      )}
    />
  );
}
