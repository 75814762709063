import React from 'react';

import { useCurrentOrganization, useSession } from 'helpers/hooks';

const Playground = () => {
  const session = useSession();
  const session2 = useSession();
  const organization = useCurrentOrganization();
  const organization2 = useCurrentOrganization();
  return (
    <div className="design-system-v2">
      <div>{session.userId}</div>
      <div>{session2.userId}</div>
      <div>{organization.id}</div>
      <div>{organization2.id}</div>
    </div>
  );
};

export default Playground;
