import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import type { Action, Notice } from '../actions';
import type { DataState } from './DataStateType';
import type { AutoSaveState } from './autoSave';
import type { ActiveUser } from 'models';

import { hydrateFromStore, reducer as requests } from 'lib/dataLoader';

import application from './application';
import autoSave from './autoSave';
import data, * as dataSelectors from './data';
import {
  selectOrganizationPlans,
  selectOrganizationSettings,
  selectOrganizationThemes,
  selectOrganizationUserFilterableFields,
  selectOrganizations,
  selectSessions,
  selectUsers,
} from './selectors';

type DataFromStore = {
  records: {};
  relationships: {};
};

export type ApplicationState = {
  notice?: Notice;
  blockingMessage?: string;
};

export type ReduxStore = {
  readonly application: ApplicationState;
  readonly data: DataState;
  readonly requests: {};
  readonly autoSave: AutoSaveState;
};

const appReducer: (
  state: ReduxStore | undefined,
  action: Action
) => ReduxStore = combineReducers({
  application,
  data,
  requests,
  autoSave,
});

// Logic to nuke the store
const rootReducer = (state: ReduxStore, action: Action): ReduxStore => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action);
  } else {
    return appReducer(state, action);
  }
};

export default rootReducer;

export const getActiveUser = (state: ReduxStore): ActiveUser | null => {
  const session = getCurrentSession(state);

  if (!session) return null;

  return session.user;
};

export const getOrganization = (state: ReduxStore) => {
  const session = getCurrentSession(state);

  if (!session) return null;

  return session.organization;
};

export const getCurrentSession = (state: ReduxStore) => {
  return hydrateFromStore(
    state.data,
    { resourceType: 'session', id: 'current' },
    {
      session: {
        user: {
          abilities: {},
        },
        organization: {
          abilities: {},
          plan: {},
          settings: {},
          customization: {},
          theme: {
            abilities: {},
          },
          homeMessages: {},
          userFilterableFields: {},
          surveyUserFilterableFields: {},
        },
      },
    }
  );
};

export const getSession = createSelector([selectSessions], sessions => {
  return getResourceAttributesById(sessions, 'current');
});

export const getCurrentUser = createSelector(
  [getSession, selectUsers],
  (session, users) => {
    const { userId } = session;
    return getResourceAttributesById(users, userId);
  }
);

export const getCurrentOrganization = createSelector(
  [getSession, selectOrganizations],
  (session, organizations) => {
    const { organizationId } = session;
    return getResourceAttributesById(organizations, organizationId);
  }
);

export const getOrganizationPlan = createSelector(
  [getCurrentOrganization, selectOrganizationPlans],
  (organization, organizationPlans) => {
    const { planId } = organization;
    return getResourceAttributesById(organizationPlans, planId);
  }
);

export const getOrganizationSettings = createSelector(
  [getCurrentOrganization, selectOrganizationSettings],
  (organization, organizationSettings) => {
    const { settingsId } = organization;
    return getResourceAttributesById(organizationSettings, settingsId);
  }
);

export const getOrganizationTheme = createSelector(
  [getCurrentOrganization, selectOrganizationThemes],
  (organization, organizationThemes) => {
    const { themeId } = organization;
    return getResourceAttributesById(organizationThemes, themeId);
  }
);

export const getOrganizationUserFilterableFields = createSelector(
  [getCurrentOrganization, selectOrganizationUserFilterableFields],
  (organization, userFilterableFields) => {
    const { userFilterableFieldsIds } = organization;
    return getResourceAttributesByIds(
      userFilterableFields,
      userFilterableFieldsIds
    );
  }
);

/*
Example usage:
getResourceAttributesById(organization, '1');
// Output:
// {
//   name: 'Intenal Something',
//   short_name: 'internal-something',
//   { ...other_attributes },
//   themeId: '1',
//   userFilterableFieldsIds: ['1', '2', '3'],
// }
*/

export const getResourceAttributesById = (
  data: DataFromStore,
  resourceId: string
) => {
  const attributes = data.records[resourceId];
  const relationships = data.relationships[resourceId] || data.relationships;
  const relationshipIds = Object.entries(relationships).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        return {
          ...acc,
          [`${key}Ids`]: value.map(relationship => relationship.id),
        };
      } else {
        return { ...acc, [`${key}Id`]: (value as any)?.id };
      }
    },
    {}
  );
  return { ...attributes, ...relationshipIds };
};

const getResourceAttributesByIds = (data, resourceIds) => {
  return ({ ...resourceIds } = data);
};

// For resources store using redux-thunk middleware
export const getResourceById = (
  dataState: DataState,
  resourceType: string,
  id: string
) => dataSelectors.getResourceById(dataState, resourceType, id);
export const getResourceByIds = (
  dataState: DataState,
  resourceType: string,
  ids: Array<string>
) => dataSelectors.getResourceByIds(dataState, resourceType, ids);
