import { AttributesOnlyOrganization } from 'models/index';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getCurrentOrganization } from 'redux/reducers';

const useCurrentOrganization = (): AttributesOnlyOrganization => {
  return useAppSelector(state => {
    const organization = getCurrentOrganization(state);

    invariant(organization, 'Organization should be defined.');

    return organization;
  });
};

export default useCurrentOrganization;
