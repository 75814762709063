import React from 'react';

import type { Organization, User } from 'models';

import { useSession } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToAdminRights,
  pathToENPSSettings,
  pathToEntities,
  pathToIntegrations,
  pathToObjectivesSettings,
  pathToReviewsSettings,
  pathToThreeSixtySettings,
  pathToTrainingSettings,
} from 'helpers/paths';

import { Button, FeatureFlagged, Icon, Text } from 'components';

import NavItem from './NavItem';
import NavSection from './NavSection';

type Props = {
  organization: Organization;
  activeUser: User;
  hideSettingsPage: () => void;
};

export default function NavSettings({
  organization,
  activeUser,
  hideSettingsPage,
}: Props) {
  const { plan } = organization || {};
  const { isImpersonated } = useSession();
  const { isOrganizationAdmin } = activeUser;
  const adminRightsEnabled = organization.featureFlags.includes('adminRights');

  return (
    <React.Fragment>
      <div className="flex items-center mb-4 px-2">
        <Button
          additionalClassName="border-0 bg-transparent text-nav-icon hover:text-nav-icon"
          onClick={hideSettingsPage}
          hasIconOnly
        >
          <Icon size="small" additionalClassName="pr-3" name="arrow_back" />
          <Text
            additionalClassName="font-semibold !text-nav-names-default"
            size={6.5}
          >
            {__('Back')}
          </Text>
        </Button>
      </div>
      <NavSection title={__('Workspace')} className="px-2">
        {adminRightsEnabled && isOrganizationAdmin && (
          <NavItem
            iconName="admin_panel_settings"
            title={__('Administrators')}
            path={pathToAdminRights()}
          />
        )}
        {plan.multiLevelAdminEnabled &&
          isImpersonated &&
          isOrganizationAdmin && (
            <NavItem
              iconName="workspaces"
              title={__('Entities')}
              path={pathToEntities()}
            />
          )}
        {isImpersonated && isOrganizationAdmin && (
          <NavItem
            iconName="integration_instructions"
            title={__('Integrations')}
            path={pathToIntegrations()}
          />
        )}
      </NavSection>
      <NavSection title={__('Product')} className="px-2">
        <NavItem
          iconName="speed"
          title={__('Reviews')}
          path={pathToReviewsSettings()}
        />
        <NavItem
          iconName="360"
          title={__('360 Feedback')}
          path={pathToThreeSixtySettings()}
        />
        <NavItem
          iconName="track_changes"
          title={__('Objectives')}
          path={pathToObjectivesSettings()}
        />
        <NavItem
          iconName="school"
          title={__('Training')}
          path={pathToTrainingSettings()}
        />
        <FeatureFlagged flag="enps">
          <NavItem
            iconName="volunteer_activism"
            title={__('eNPS')}
            path={pathToENPSSettings()}
          />
        </FeatureFlagged>
      </NavSection>
    </React.Fragment>
  );
}
